import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

import style from './pages.module.scss';

const Gear = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gear.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Gear"
        description="Rob Lea's gear for his bike journey across America includes: Fat Tire Beer (Sponsor), Specialized bike, Swift Industries handlebar bag, Julbo eyewear, and more."
        image="/images/meta-gear.jpg"
        pathname="/gear"
      />
      <div className={[style.page, style.page__gear].join(' ')}>
        <div className={style.feature_odd}>
          <div className={style.container}>
            <h1>My Gear</h1>
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ marginTop: 40 }}
            />
            <ul>
              <li>
                <b>Beer:</b> Fat Tire Beer (Sponsor)
              </li>
              <li>
                <b>Bike:</b> Specialized
              </li>
              <li>
                <b>Handlebar Bag:</b> Swift Industries
              </li>
              <li>
                <b>Kit #1:</b> Cole Sport
              </li>
              <li>
                <b>Kit #2:</b> Pearl Izumi
              </li>
              <li>
                <b>Eyewear:</b> Julbo
              </li>
              <li>
                <b>Rain Coat:</b> Pearl Izumi
              </li>
              <li>
                <b>Headphones:</b> Apple AirPods
              </li>
              <li>
                <b>Flipflops:</b> Keen
              </li>
              <li>
                <b>Insoles:</b> Surefoot
              </li>
              <li>
                <b>Energy Bars:</b> Clif
              </li>
              <li>More to be added soon...</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gear;
